<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>
        <v-container xl fluid>
            <v-row>
                <v-overlay :z-index="999" :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
 
                </v-col>
                <v-col cols="12" lg="12">
                    <v-row align="center" class="pa-0 ma-0">

                        <v-col v-if="getNomina.procesando_timbres" class="ma-0 pa-0 d-flex justify-space-between cssTitleProgress" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span>{{nominaPorcentaje<100?'Timbrado de nómina en proceso':'Timbrado de nómina finalizado'}}</span> 
                            <span>{{'Procesado '+nominaProcesados+' de '+nominaTotal}}</span>
                        </v-col>
                        <v-col v-if="getNomina.procesando_timbres" class="ma-0 pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-progress-linear
                                :value="nominaPorcentaje"
                                height="10"
                                rounded
                                background-color="#DADADA"
                                :color="nominaPorcentaje<100?'#F2C94C':'#219653'"
                            >
                                <span class="cssTitleProgress">{{ Math.ceil(nominaPorcentaje) }}%</span>
                            </v-progress-linear>
                        </v-col>
                        <v-col class="ma-0 pa-0" cols="12" xs="6" sm="7" md="8" lg="8" xl="8">
                            <v-tabs v-model="tab" background-color="transparent" class="ml-1 my-0 pa-0">
                                <v-tabs-slider color="transparent"></v-tabs-slider>
                                <!-- <v-tab v-for="tabTitle in tabs" :key="tabTitle" class="pa-0 " >
                                    <span class="tabText mx-2">{{ tabTitle }}</span>
                                </v-tab> -->

                                <v-tab class="pa-0 " >
                                    <span :class="tab==1?'tabTextDisable':'tabText'" class="tabText mx-2">{{ 'Empleados acumulados' }}</span>
                                </v-tab>
                                <v-tab  class="pa-0 " >
                                    <span :class="tab==0?'tabTextDisable':'tabText'" class="tabText mx-2">{{ 'Empleados timbrados'}}</span>
                                </v-tab>
                            </v-tabs>
                        </v-col>
                        <v-col cols="12" xs="6" sm="5" md="4" lg="4" xl="4" class="px-0 mx-0 d-flex justify-end"> 
                            <template v-if="tab==0 && !overlayProceso">
                                <v-tooltip bottom  >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            :disabled="!masivo"
                                            fab
                                            color="#FFFFFF"
                                            class="elevation-0 mr-3 btn-acciones"
                                            @click="abrirModalTimbre(null)"
                                        >
                                            <v-icon color="#101C5A">mdi-bell-ring-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Timbrar seleccionados</span>
                                </v-tooltip>
                                <v-tooltip bottom  >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            :disabled="!masivo"
                                            fab
                                            color="#FFFFFF"
                                            class="elevation-0 btn-acciones"
                                            @click="desacumularAcumulado(item, 'empleados')"
                                        >
                                            <v-icon class="outlined_v_icon" color="#101C5A">downloading</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Desacumular seleccionados</span>
                                </v-tooltip>
                                <v-btn
                                    fab
                                    color="#FFFFFF"
                                    class="elevation-0 ml-3 mr-3 btnBuscar"
                                    @click="abrirModalBuscar()"
                                >
                                    <v-icon color="#96999A">mdi-magnify</v-icon>
                                </v-btn>
                            </template>


                            <template v-if="tab==1 && !overlayProceso">
                                <v-tooltip bottom  >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            :disabled="!btnTimbreMasivo"
                                            fab
                                            color="#FFFFFF"
                                            class="elevation-0 btn-acciones mr-3"
                                            @click="desacumularAcumulado(null, 'empleados')"
                                        >
                                            <v-icon class="outlined_v_icon" color="#101C5A">downloading</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Desacumular seleccionados</span>
                                </v-tooltip>
                                <v-menu offset-y content-class="menuClassAcciones">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dark
                                            class="btnAcciones pr-1 mr-3"
                                            v-bind="attrs"
                                            v-on="on"
                                            elevation="0"
                                        >
                                            Acciones 
                                            <template  v-for="(item,valor) in attrs" >
                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                            </template>
                                        </v-btn>
                                        
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            :style="!btnTimbreMasivo? 'cursor:not-allowed;':''"
                                            @click="!btnTimbreMasivo?'#':descargarTimbres()"
                                        >
                                            <v-list-item-title :style="!btnTimbreMasivo?'color:#BDBDBD;':'color:#828282'">Descargar seleccionados</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :style="!btnTimbreMasivo? 'cursor:not-allowed;':''"
                                            @click="!btnTimbreMasivo?'#':abrirModalTimbreRetimbre(null, 'timbrarErrores')"
                                        >
                                            <v-list-item-title :style="!btnTimbreMasivo?'color:#BDBDBD;':'color:#828282'">Timbrar seleccionados</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :style="!btnTimbreMasivo? 'cursor:not-allowed;':''"
                                            @click="!btnTimbreMasivo?'#':abrirModalTimbreRetimbre(null, 'timbrarCancelados')"
                                        >
                                            <v-list-item-title :style="!btnTimbreMasivo?'color:#BDBDBD;':'color:#828282'">Timbrar cancelados seleccionados</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :style="!btnTimbreMasivo? 'cursor:not-allowed;':''"
                                            @click="!btnTimbreMasivo?'#':abrirModalCancelar(null, 'empleados')"
                                        >
                                            <v-list-item-title :style="!btnTimbreMasivo?'color:#BDBDBD;':'color:#828282'">Cancelar seleccionados</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            :style="!btnTimbreMasivo? 'cursor:not-allowed;':''"
                                            @click="!btnTimbreMasivo?'#':abrirModalTimbreRetimbre(null, 'retimbrar')"
                                        >
                                            <v-list-item-title :style="!btnTimbreMasivo?'color:#BDBDBD;':'color:#828282'">Retimbrar seleccionados</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            :style="!btnTimbreMasivo? 'cursor:not-allowed;':''"
                                            @click="!btnTimbreMasivo?'#':correo(null, 'empleados')"
                                        >
                                            <v-list-item-title :style="!btnTimbreMasivo?'color:#BDBDBD;':'color:#828282'">Enviar correo</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-btn
                                    fab
                                    color="#FFFFFF"
                                    class="elevation-0 mr-3 btnBuscar"
                                    @click="abrirModalBuscar()"
                                >
                                    <v-icon color="#96999A">mdi-magnify</v-icon>
                                </v-btn>
                            </template>

                        </v-col>
                    </v-row>

                    <v-tabs-items v-model="tab" class="mb-10">
                        <v-tab-item :key="0" style="background:#EFEFEF;">
                            <v-overlay :value="overlayProceso" :absolute="true">
                                <div v-if="nominaPorcentaje<100" class="align-center justify-center text-center">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="#2DB9D1"
                                    >
                                        <v-img
                                            src="/static/icon/favicon.ico"
                                            :width="60"
                                        ></v-img>
                                    </v-progress-circular>
                                    <p class="mt-5">En proceso...</p>
                                </div>

                                <div v-else class="align-center justify-center text-center">
                                    <!-- <v-btn
                                        rounded
                                        class="btnProgress"
                                        @click="clickOverlayProceso()"
                                    >
                                        Reconsultar
                                    </v-btn> -->
                                </div>
                            </v-overlay>
                            <simple-table
                                ref="tablageneral"
                                :isSelectable="true"
                                :selected="selected_general_acumulado"
                                @selectAllEvent="selectAllAcumulados"
                                :rows="itemsEmpleadosTablaAcumulados"
                                :columns="columnsAcumulados"
                                :perPage="[10,25,50,100]"
                                :loading="isLoading"
                                :valCheck="'empleado_id'"
                                :idTabla="'idTablaAcumulados'"
                            >
                                <tbody slot="body" slot-scope="{ data }">
                                    <tr :key="item.empleado_id" v-for="(item, index) in data">
                                        <td style="width:115px !important; ">
                                            <div >
                                                <v-checkbox class="mb-5" primary hide-details :value="item.empleado_id" v-model="selected_general_acumulado" ></v-checkbox>
                                            </div>
                                        </td> 
                                        <td><div>{{item.empleado.nombre_completo}}</div></td>
                                        <td><div>{{formatoMoneda(item.total_percepciones)}}</div></td>
                                        <td><div>{{formatoMoneda(item.total_deducciones)}}</div></td>
                                        <td><div>{{formatoMoneda(item.neto_pagar)}}</div></td>
                                        <td>
                                            <div class="tblOpciones">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModalTimbre(item)">
                                                            <v-icon class="tamIconoBoton iconoDelgadoBoton">mdi-bell-ring-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Timbrar</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="desacumularAcumulado(item, 'empleado')">
                                                            <v-icon class="outlined_v_icon tamIconoBoton iconoDelgadoBoton">downloading</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Desacumular</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </simple-table>
                        </v-tab-item>
                        <v-tab-item :key="1" style="background:#EFEFEF;">

                            <!-- Tabla timbres -->

                            <v-overlay :value="overlayProceso" :absolute="true">
                                <div v-if="nominaPorcentaje<100" class="align-center justify-center text-center">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="#2DB9D1"
                                    >
                                        <v-img
                                            src="/static/icon/favicon.ico"
                                            :width="60"
                                        ></v-img>
                                    </v-progress-circular>
                                    <p class="mt-5">En proceso...</p>
                                </div>

                                <div v-else class="align-center justify-center text-center">
                                    <!-- <v-btn
                                        rounded
                                        class="btnProgress"
                                        @click="clickOverlayProceso()"
                                    >
                                        Reconsultar
                                    </v-btn> -->
                                </div>
                            </v-overlay>
                            <simple-table
                                ref="tabla"
                                :isSelectable="showSelect"
                                :selected="selected_general"
                                @selectAllEvent="getSelected_general"
                                :rows="itemsEmpleados"
                                :columns="columns"
                                :perPage="[10, 25, 50, 100]"
                                :loading="isLoading"
                            >
                                <tbody slot="body" slot-scope="{ data }">
                                    <tr :key="item.id" v-for="item in data" class="alto-tr">
                                        <td style="width:80px !important; padding-right:4px !important;">
                                            <div>
                                                <v-checkbox class="mb-5" primary hide-details :value="item.id" v-model="selected_general"></v-checkbox>
                                            </div>
                                        </td> 
                                        <td style="padding-left:8px !important;">
                                            <div>
                                                {{item.uuid}}
                                            </div>
                                        </td>

                                        <td v-if="tipoDePeriodo =='FINIQUITO'"> <div>{{item.tipo_indemnizacion ? 'Indemnización':'Nómina'}}</div> </td>
                                        <td><div>{{item.nombre_completo}}</div></td>
                                        <!-- <td>
                                            <div>
                                                <v-list color="transparent" class="py-0 my-0">
                                                    <v-list-item two-line class="py-0 my-0 px-0">
                                                        <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                            <img :src="urlAvatar + item.empleado.foto" alt="Empleado">
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="ml-0 py-0 my-0">
                                                            <v-list-item-title class="list-item-titulo">{{item.empleado.nombre_completo }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </td> -->
                                        <td>
                                            <div>
                                                <template v-if="item.cancelado">
                                                    <v-chip
                                                        class="mx-0 px-0 disable-events"
                                                        color="transparent"
                                                        :text-color="'#D93025'" 
                                                        outlined
                                                        :ripple="false"
                                                    >
                                                        <template>
                                                            <img
                                                                style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                src="/static/icon/cancelado1.png"
                                                                alt="Estatus"
                                                            />
                                                            Cancelado
                                                        </template>
                                                    </v-chip>
                                                </template>
                                                <template v-else-if="item.pendiente_cancelacion">
                                                    <v-chip
                                                        class="mx-0 px-0 disable-events text-wrap py-6"
                                                        color="transparent"
                                                        :text-color="'#FED543'" 
                                                        outlined
                                                        :ripple="false"
                                                    >
                                                        <template>
                                                            <img
                                                                style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                src="/static/icon/pendiente1.png"
                                                                alt="Estatus"
                                                            />
                                                            Cancelación pendiente
                                                        </template>
                                                    </v-chip>
                                                </template>
                                                <template v-else>
                                                    <v-chip
                                                        class="mx-0 px-0 disable-events"
                                                        color="transparent"
                                                        :text-color="textColor(item.errores, item.uuid)" 
                                                        outlined
                                                        :ripple="false"
                                                    >   
                                                        <template v-if="item.errores === null && item.uuid !== ''">
                                                            <img
                                                                style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                src="/static/icon/aprovado.png"
                                                                alt="Estatus"
                                                            />
                                                            Correcto
                                                        </template>
                                                        <template v-else-if="item.errores != null && item.uuid === ''">
                                                            <img
                                                                style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                src="/static/icon/error.png"
                                                                alt="Estatus"
                                                            />
                                                            Error
                                                        </template>
                                                        <template v-else-if="item.errores != null && item.uuid !== ''">
                                                            <img
                                                                style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                src="/static/icon/error.png"
                                                                alt="Estatus"
                                                            />
                                                            Error
                                                        </template>
                                                        <template v-else-if="item.uuid === '' && item.errores === null">
                                                            <img
                                                                style="height: 20px; width: 20px; background-color: transparent; margin-right: 4px;"
                                                                src="/static/icon/cancelado1.png"
                                                                alt="Sin timbrar"
                                                            />
                                                            Sin timbrar
                                                        </template>
                                                    </v-chip>
                                                </template>
                                            </div>
                                        </td>
                                        <td style="min-width:210px!important; max-width:211!important; width:210px; padding-left:8px; padding-right:8px;">
                                            <div 
                                                class="tblOpciones mx-0 pa-0" 
                                                style="
                                                    min-width:210px!important; 
                                                    max-width:211!important; 
                                                    width:210px;
                                                    position:absolute; 
                                                    z-index:3; 
                                                    overflow:visible;
                                                "
                                            >
                                                <v-speed-dial
                                                    v-if="item.uuid !== ''"
                                                    :id="item.id"
                                                    direction="left"
                                                    :open-on-hover="true"
                                                    transition="scale-transition"
                                                    class="elevation-0 d-inline-flex pa-0 ma-0 btnSpeelDial"
                                                    style="top:-20px"
                                                >
                                                    <template v-slot:activator>
                                                        <v-btn
                                                            :id="item.id"
                                                            color="transparent"
                                                            fab
                                                            small
                                                            elevation="0"
                                                            class="pa-0 ma-0 botonHover"
                                                        >
                                                            <v-icon 
                                                                color="#96999A" 
                                                                v-text="'$get_app_outline'"
                                                                class="iconoDelgadoBoton"
                                                            >
                                                                cloud_download
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-on="on"
                                                                fab
                                                                small
                                                                color="#FFFFFF"
                                                                @click="descargar(item,'pdf')"
                                                                elevation="0"
                                                            >
                                                                <v-icon color="red">mdi-file-pdf</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>PDF</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-on="on"
                                                                fab
                                                                small
                                                                color="#FFFFFF"
                                                                @click="descargar(item,'xml')"
                                                                elevation="0"
                                                            >
                                                                <v-icon color="green">mdi-file-xml</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>XML</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-on="on"
                                                                fab
                                                                small
                                                                color="#FFFFFF"
                                                                @click="descargar(item,'zip')"
                                                                elevation="0"
                                                            >
                                                                <v-icon color="blue">mdi-zip-box</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>ZIP</span>
                                                    </v-tooltip>
                                                </v-speed-dial>
                                                <v-menu buttom left class="elevation-0 d-inline-flex" content-class="mt-0 menuClassAcciones"> 
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon 
                                                            fab
                                                            dark
                                                            small
                                                            class="elevation-0"
                                                            style="top:-20px"
                                                        >
                                                            <template  v-for="(item,valor) in attrs" >
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                            </template>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <v-list-item
                                                            v-if="item.uuid !== '' && !item.cancelado"
                                                            @click="correo(item, 'empleado')"
                                                        >
                                                            <v-list-item-title>Enviar correo</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="item.errores !== null"
                                                            @click="viewErrors(item)"
                                                        >
                                                            <v-list-item-title>Ver errores</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="item.errores !== null && !item.cancelado && item.uuid===''"
                                                            @click="abrirModalTimbreRetimbre(item, 'timbrarErrores')"
                                                        >
                                                            <v-list-item-title>Timbrar</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="item.cancelado && item.clave_motivo_cancelacion === '02'"
                                                            @click="abrirModalTimbreRetimbre(item, 'timbrarCancelados')"
                                                        >
                                                            <v-list-item-title>Timbrar</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="!item.cancelado && !item.retimbrado && item.uuid !== ''"
                                                            @click="abrirModalTimbreRetimbre(item, 'retimbrar')"
                                                        >
                                                            <v-list-item-title>Retimbrar</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="!item.cancelado && item.uuid !== ''"
                                                            @click="abrirModalCancelar(item, 'empleado')"
                                                        >
                                                            <v-list-item-title>Cancelar timbre</v-list-item-title>
                                                        </v-list-item>


                                                        <!-- <v-list-item 
                                                            v-if="((item.cancelado && !item.retimbrado) || (item.cancelado==false && item.retimbrado==false && item.errores != null))"
                                                            @click="desacumular(item)"
                                                        >
                                                            <v-list-item-title>Desacumular</v-list-item-title>
                                                        </v-list-item> -->
                                                        <v-list-item 
                                                            @click="desacumular(item)"
                                                        >
                                                            <v-list-item-title>Desacumular</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>

                                                <v-tooltip top v-if="item.requiere_retimbrar">
                                                    <template v-slot:activator="{ on }">
                                                        <v-img
                                                            v-on="on"
                                                            class="ml-2 mt-n3"
                                                            style="margin-bottom:2px"
                                                            max-height="23"
                                                            max-width="23"
                                                            src="/static/svg/signoExclamacion.svg"
                                                        ></v-img>
                                                    </template>
                                                    <span>Es necesario retimbrar.</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </simple-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                            <div class="headerModal-filter">
                                <h2 class="titleModalBuscar">{{ modalTitle }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <v-container grid-list-md id="contenedor">
                                <v-row class="px-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="itemsSearchEmployed"
                                            item_text="nombre_completo"
                                            item_value="empleado_id"
                                            @dataMultiple="(data)=> modelSearchEmployed = data"
                                            :valorDefecto="modelSearchEmployed"
                                            label="Empleados"
                                            ref="multEmpleados"
                                        />
                                    </v-col>
                                    <v-col v-if="tab==1" cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <v-autocomplete
                                            outlined
                                            label="Estatus"
                                            :items="estatusTimbres"
                                            item-text="descripcion"
                                            item-value="valor"
                                            persistent-hint
                                            v-model="estatusTimbre"
                                            class="pa-0 ma-0"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="buscar()"
                            >
                                Buscar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogCancelar" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                            <div class="headerModal-filter">
                                <h2 class="titleModalBuscar">{{ modalTitle }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <v-container grid-list-md id="contenedor">
                                <v-row class="px-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <v-radio-group
                                            v-model="claveMotivo"
                                            mandatory
                                        >
                                            <v-radio
                                                label="Clave: 01: Comprobante emitido con errores con relación."
                                                value="01"
                                                class="radioLabel"
                                                :disabled="cancelacionClave0203"
                                            ></v-radio>
                                            <v-radio
                                                label="Clave: 02: Comprobante emitido con errores sin relación."
                                                value="02"
                                                class="radioLabel"
                                                :disabled="cancelacionClave01"
                                            ></v-radio>
                                            <v-radio
                                                label="Clave 03: No se llevó a cabo la operación"
                                                value="03"
                                                class="radioLabel"
                                                :disabled="cancelacionClave01"
                                            ></v-radio>
                                            <p v-if="claveMotivo=='03'" class="pNota mb-0">Nota: Una vez aplicado el motivo de cancelación con clave "03", 
                                                el sistema no permitirá realizar ningún timbre, ya que este supuesto 
                                                aplica cuando se facturó una operación que no se concreta.
                                            </p>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cerrar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="cancelarTimbrado(datos, accionEmp)"
                            >
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>



            <template class="borde-card">
                <v-dialog v-model="dialogAcumulados" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalAcumulados"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img src="/static/modal/nominaTimbrar.png" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModalAcumulados">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModalAcumulados" grid-list-md id="contenedor">
                                    

                                    <table class="tbl-modal">
                                        <thead class="tbl-header-modal">
                                            <tr>
                                                <th  class="pl-7 firstth">Empleado</th>
                                                <th class="lastth">Fecha pago</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="datosTablaAcumulados.length == 0" >
                                                <td class="emptyTableModal" colspan="11">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(empleado, index) in datosTablaAcumulados">
                                                <tr :key="index">
                                                    <td class="pl-5">
                                                        <span>
                                                            {{empleado.empleado.nombre_completo}}
                                                        </span>
                                                    </td>
                                                    <td class="pr-5 py-2">
                                                        <VuetifyDatePicker
                                                            outlined
                                                            rules="required"
                                                            :valorDefecto="empleado.periodo.fecha_de_pago"
                                                            v-model="empleado.periodo.fecha_de_pago"
                                                            label=""
                                                            placeholder="dd/mm/aaaa"
                                                            dense
                                                            hideDetails
                                                        />
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="timbrarModal()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Timbrar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogTimbres" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalAcumulados"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModalTimbres">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModalTimbres" grid-list-md id="contenedor">
                                    <div v-if="accionTimbreRetimbre == 'retimbrar' && datosTablaTimbres.length > 0" class="alert-retimbrado mt-5">
                                        <div>&nbsp;</div>
                                        <div class="d-flex">
                                            <img src="/static/modal/alerta.svg" class="mr-4" />
                                            <div class="alert-titulo">Advertencia</div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="icono-espacio"></div>
                                            <div v-if="datosTablaTimbres.length == 1" class="alert-sub">¿Estás seguro que deseas realizar el retimbrado de la nómina del empleado: “<span class="alert-sub-2">{{ nombre_empleado }}</span>”? Se generará un recibo nuevo y automáticamente se cancelará el recibo anterior.</div>
                                            <div v-if="datosTablaTimbres.length > 1" class="alert-sub">¿Estás seguro que deseas realizar el retimbrado de la nómina de los siguientes empleados? Se generará un recibo nuevo y automáticamente se cancelará el recibo anterior.</div>
                                        </div>
                                        <div>&nbsp;</div>
                                    </div>
                                    <table class="tbl-modal">
                                        <thead class="tbl-header-modal">
                                            <tr>
                                                <th  class="pl-7 firstth">Empleado</th>
                                                <th class="lastth">Fecha pago</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="datosTablaTimbres.length == 0" >
                                                <td class="emptyTableModal" colspan="11">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(empleado, index) in datosTablaTimbres">
                                                <tr :key="index">
                                                    <td class="pl-5">
                                                        <span >
                                                            {{empleado.nombre_completo}}
                                                        </span>
                                                    </td>
                                                    <td class="pr-5 py-2">
                                                        <VuetifyDatePicker
                                                            outlined
                                                            rules="required"
                                                            :valorDefecto="empleado.fecha_pago"
                                                            v-model="empleado.fecha_pago"
                                                            label=""
                                                            placeholder="dd/mm/aaaa"
                                                            dense
                                                            hideDetails
                                                        />
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="timbreRetimbre()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Timbrar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogMensaje" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalTimbre"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="justify-center align-center text-center card_titulo">
                                <div>
                                    <img :src="'/static/icon/alert_success.png'" class="imgModal pb-5" />
                                    <h2 class="titleModalTimbre pb-5">Proceso de timbre terminado</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <v-container  grid-list-md id="contenedor">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                        El proceso de timbre terminó satisfactoriamente.
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 justify-center align-center text-center textTimbre">
                                        Procesados: {{procesados}} <br>
                                        Con error: {{conError}}
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="card_accion pt-0">
                                <v-container  grid-list-md class="ma-0 pa-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#1E2245'
                                            dark
                                            @click="cerrarModalMensaje()"
                                        >
                                            ok
                                        </v-btn>
                                    </v-col>
                                </v-container>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Timbres requeridos</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Registro patronal</th>
                                                    <th class="errorth">Requeridos</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Disponibles</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresRP.length == 0" >
                                                    <td class="emptyTableErrores" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresRP">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{error.registro_patronal}}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError01">{{error.requeridos}}</div></td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.disponibles}}</div> </td>
                                                        </tr>
                                                        
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import Datatable from '@/components/datatable/Datatable.vue';
import urlImg from '@/plugins/Urlimagen';
import queries from '@/queries/Timbres';
import Notify from '@/plugins/notify';
import LoadingCliker from '@/components/LoadingCliker';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';

import queriesNomina from '@/queries/Nomina';
import env from "@/plugins/enviroment";
import Echo from "laravel-echo";
window.Pusher = require('pusher-js');


export default {
    components: {
        "simple-table": DatatableSimple,
        'data-table': Datatable,
        'loading-cliker':LoadingCliker,
        VuetifyDatePicker,
        VuetifyMultipleAutocomplete
    },
    data() {
        return {
            columns         : [
                {
                    label:"UUID",
                    name:"uuid",
                    filterable:false
                },
                {
                    label: "Empleado", 
                    name: "nombre_completo",
                    filterable: false
                },
                {
                    label: "Estatus", 
                    name: "estatus",
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'detalle',
                    filterable: false,
                    align: 'center',
                }
            ],
            itemsLinks      : [
                {
                    text: 'Nóminas acumuladas',
                    disabled: false,
                    to: '/calculoNominaAcumulado',
                },
                {
                    text: 'Detalle del periodo',
                    disabled: true,
                    to: '/detalleTimbre',
                },
            ],
            filters         : {AND:[]},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            timbre       : {
                id            : 0,

            },
            nomina    : null,
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            loading         : false,
            loadingModal    : false,
            isSaving        : false,
            isClosing       : false,
            imagen          : '',
            rol             : null,
            datosLogin      : null,
            clientes        : [],
            empresas        : [],
            cliente_value   : null,
            empresa_value   : null,
            urlAvatar       : urlImg.getURL(),
            showSelect      : true,
            selected_general: [],
            itemsEmpleados  : [],
            loadingLottie   : false,
            fraseLoading    : '',
            btnTimbreMasivo : false,
            overlay         : false,
            fraseOverlay    : "Descargando",
            modalTitle      : "Búsqueda avanzada",
            estatusTimbres  : [
                {id:1, descripcion:"Timbrado",valor:"timbrado"}, 
                {id:2, descripcion:"Error", valor:"error"},
                {id:3, descripcion:"Cancelado", valor:"cancelado"},
                {id:4, descripcion:"Retimbrado", valor:"retimbrado"}
            ],
            estatusTimbre: null,
            itemsEmpleadosOriginal : [],
            tabs: ['Empleados acumulados', 'Empleados timbrados'],
            tab:0,
            dialogCancelar: false,
            claveMotivo: '01',
            datos:null,
            accionEmp:null,

            /* datos de para pestaña empleados acumulados */
            urlAcumulado        : "nominas-timbres/detalle",
            columnsAcumulados    : [
                {
                    label: 'Empleado',
                    name: 'nombre_completo',
                    filterable: false
                },
                {
                    label: 'Total percepciones',
                    name: 'total_percepciones',
                    filterable: false
                },
                {
                    label: 'Total deducciones',
                    name: 'total_deducciones',
                    filterable: false
                },
                {
                    label: 'Neto a pagar',
                    name: 'neto_pagar',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filtersAcumulado     : {
                activo    :true,
                paginate  :true,
                order     :"asc",
                nomina_id : null
            },
            checkAll                : false,
            selected_general_acumulado: [],
            unselected_general      : [],
            masivo                  : false,
            itemsEmpleadosTablaAcumulados: [],
            empleadoBuscar          : null,

            dialogAcumulados        : false,
            isLoadingModalAcumulados: false,
            datosTablaAcumulados    : [],
            cancelarModal           : false,
            nominaPorcentaje        : 0,
            nominaTotal             : 0,
            nominaProcesados        : 0,
            getNomina               : {id:0, procesando_timbres:false},
            overlayProceso          : false,
            dialogTimbres           : false,
            isLoadingModalTimbres   : false,
            datosTablaTimbres       : [],
            accionTimbreRetimbre    : '',
            dialogMensaje           : false,
            procesados              : 0,
            conError                : 0,
            cancelacionClave01      : false,
            cancelacionClave0203    : false,
            tipoDePeriodo           : null,
            dialogErrores           : false,
            erroresRP               : [],
            nombre_empleado         : '',
            itemsSearchEmployed     : [],
            modelSearchEmployed     : [],
        }
    },
    watch: {
        selected_general:function(value) {
            if(value.length > 0 ){
                this.btnTimbreMasivo =  true;
            }
            else{
                this.btnTimbreMasivo =  false;
            }
        },
        selected_general_acumulado:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
        tab:function(value){
            if(value == 0){
                this.selected_general_acumulado=[];
                this.$apollo.queries.getNomina.refetch();
                this.getEmpleados(this.nomina.id, null, null, 'updated');
            }
            else if(value == 1){
                this.selected_general=[];
                this.$apollo.queries.getNomina.refetch();
                this.getEmpleados(this.nomina.id, null, null, 'updated');
            }
        }
    },
    methods: {
        getSelected_general(isSelected, items) {
            this.selected_general = items;
        },
        enviarEmail(data){
            this.loadingLottie = true;
            this.fraseLoading  = 'Enviando E-mail';
            setTimeout(()=>{ this.loadingLottie = false }, 5000);
        },
        viewErrors(data){
            Notify.Warning(
                "ERRORES",
                data.errores,
                "Aceptar",
                () => {
                    
                }
            )
        },
        timbrar(data, accion){

            let mensaje = '';
            let param = { nominas: []}

            if(accion === 'empleado'){
                mensaje = "¿Estás seguro que deseas timbrar al empleado "+data.nombre_completo+"?";
                param = { nominas: [data.id]}
            }
            else{
                mensaje = "¿Estás seguro que deseas timbrar los empleados seleccionados?"
                param = { nominas: this.selected_general}
            }

            Notify.Alert(
                "ADVERTENCIA",
                mensaje,
                "Timbrar",
                () => {
                    this.fraseLoading = "Timbrando";
                    this.loadingLottie = true;
                    apiCalculoNomina.timbraErrores(param).then(response => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        if(response.status === 202){
                            // mensaje para  response.data.message
                            if(typeof response.data.message === 'string'){
                                Notify.ErrorToast(response.data.message +" Favor de verificar el detalle");
                            }else{
                                let error = Object.values(response.data.message);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                leyenda += " Favor de verificar el detalle";
                                Notify.ErrorToast(leyenda);
                            }

                        }
                        if(response.status === 200){
                            Notify.Success("Timbrado", "El timbrado se realizo correctamente.");
                        }
                        this.getEmpleados(this.nomina.id, null, null, 'updated');
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        abrirModalCancelar(data, accion){
            this.dialogCancelar = true;
            this.modalTitle = 'Seleccionar motivo de cancelación';
            this.datos = data;
            this.accionEmp = accion;
            if(data != null){
                //if(data.retimbrado && !data.cancelado){
                if(data.pendiente_cancelacion){
                    this.cancelacionClave01 = true;
                    this.cancelacionClave0203 = false;
                }

                //if(!data.retimbrado && !data.cancelado){
                if(!data.pendiente_cancelacion){
                    this.cancelacionClave01 = false;
                    this.cancelacionClave0203 = true;
                    this.claveMotivo = '02'
                }
            }
            else{
                this.cancelacionClave01 = false;
                this.cancelacionClave0203 = false;
            }
        },
        async cancelarTimbrado(data, accion){

            let mensaje = '';
            let param = {};
            let timbres = [];


            if(accion === 'empleado'){
                mensaje = "¿Estás seguro que deseas cancelar la nómina timbrada del empleado "+data.nombre_completo+"?";
                param = { 
                    nomina_id: this.nomina.id,
                    timbres: [data.id],
                    clave_motivo: this.claveMotivo
                }
            }
            else{
                mensaje = "¿Estás seguro que deseas cancelar la nómina de los empleados seleccionados?. No se cancelaran los empleados con estatus 'Error'"
                this.datosTablaTimbres = [];
                if(this.claveMotivo == '01'){
                    this.datosTablaTimbres = await this.itemsEmpleados.filter((empleado) => this.selected_general.includes(empleado.id) && (empleado.pendiente_cancelacion))
                }
                else{
                    this.datosTablaTimbres = await this.itemsEmpleados.filter((empleado) => this.selected_general.includes(empleado.id) && (!empleado.pendiente_cancelacion))
                    //this.datosTablaTimbres = this.selected_general
                }

                if(this.datosTablaTimbres.length==0){
                    return
                }
                else{
                    this.datosTablaTimbres.forEach(item => {
                        timbres.push(item.id)
                    })
                }

                param = { 
                    nomina_id: this.nomina.id,
                    timbres: timbres,
                    clave_motivo: this.claveMotivo
                }


            }

            Notify.Alert(
                "ADVERTENCIA",
                mensaje,
                "Cancelar timbrado",
                () => {
                    this.fraseLoading = "Cancelando";
                    this.loadingLottie = true;
                    apiCalculoNomina.cancelar(param).then(response => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        
                        if(response.status === 200){
                            Notify.Success("Cancelado", "La cancelación se realizó correctamente.");
                        }
                        else if(response.status == 202){
                            this.erroresRP = response.data.items;
                            this.dialogErrores = true;
                            this.isSaving = false;
                        }
                        this.cerrarModal();
                        this.getEmpleados(this.nomina.id, null, null, 'updated');
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },

        async abrirModalTimbreRetimbre(datosEmpleado = null, accion){
            this.dialogTimbres =  true;
            this.isLoadingModalTimbres = true;
            this.imagen = "/static/modal/nominaTimbrar.png";
            this.datosTablaTimbres = [];

            if(datosEmpleado){
                this.selected_general = [];
                this.selected_general.push(datosEmpleado.id);
                this.cancelarModal = true;
            }
            else{
                this.cancelarModal = false;
            }

            if(accion == 'timbrarCancelados'){
                this.tituloModal = 'Timbrar cancelados';
                this.datosTablaTimbres = await this.itemsEmpleados.filter((empleado) => this.selected_general.includes(empleado.id) && (empleado.clave_motivo_cancelacion == "02"))
                this.accionTimbreRetimbre = 'timbrarCancelados';
            }
            else if(accion == 'timbrarErrores'){
                this.tituloModal = 'Timbrar';
                this.datosTablaTimbres = await this.itemsEmpleados.filter((empleado) => this.selected_general.includes(empleado.id) && (empleado.errores !== null && !empleado.cancelado && empleado.uuid===''))
                this.accionTimbreRetimbre = 'timbrarErrores';
            }
            else if(accion == 'retimbrar'){
                this.tituloModal = 'Retimbrar nómina';
                this.datosTablaTimbres = await this.itemsEmpleados.filter((empleado) => this.selected_general.includes(empleado.id) && (!empleado.cancelado && !empleado.retimbrado && empleado.uuid !== ''))
                this.accionTimbreRetimbre = 'retimbrar';
                this.imagen = "/static/modal/nominaRetimbrar.png";

                if(this.datosTablaTimbres.length == 1){
                    this.nombre_empleado = this.datosTablaTimbres[0].nombre_completo;
                } 
                    
            }

            this.isLoadingModalTimbres = false;
           
        },
        timbreRetimbre(){
            this.isSaving = true;
            if(this.accionTimbreRetimbre == 'timbrarCancelados'){
                this.timbrarCanceladosConFecha();
            }
            else if(this.accionTimbreRetimbre == 'timbrarErrores'){
                this.timbrarErroresConFecha();
            }
            else if(this.accionTimbreRetimbre == 'retimbrar'){
                this.retimbrarConFecha();
            }
        },
        retimbrarConFecha(){
            if(this.datosTablaTimbres.length == 0){
                this.isSaving = false;
                return
            }
            //this.fraseLoading = "Retimbrando";
            //this.loadingLottie = true;

            
            let nominas = [];

            this.datosTablaTimbres.forEach(item => {
                nominas.push({
                    nomina_id   : item.id,
                    fecha       : item.fecha_pago,
                })
            })

            let param = {nominas};
            apiCalculoNomina.retimbrar(param).then(response => {
                if(response.status == 200){
                    this.loadingLottie=false; 
                    this.$refs.tabla.unselectAll();
                    this.selected_general=[];
                    this.cerrarModal();
                    this.isSaving = false;
                    this.$apollo.queries.getNomina.refetch();

                }
                else if(response.status == 202){
                    this.erroresRP = response.data.items;
                    this.dialogErrores = true;
                    this.isSaving = false;
                }
            })
            .catch(err => {
                this.loadingLottie=false; 
                this.isSaving = false;
                this.$refs.tabla.unselectAll();
                this.selected_general=[];
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        timbrarErroresConFecha(){
            if(this.datosTablaTimbres.length == 0){
                this.isSaving = false;
                return
            }
            //this.fraseLoading = "Timbrando";
            //this.loadingLottie = true;

            
            let nominas = [];

            this.datosTablaTimbres.forEach(item => {
                nominas.push({
                    nomina_id   : item.id,
                    fecha       : item.fecha_pago,
                })
            })

            let param = {nominas};
            apiCalculoNomina.timbraErrores(param).then(response => {
                if(response.status == 200){
                    this.loadingLottie=false; 
                    this.$refs.tabla.unselectAll();
                    this.selected_general=[];
                    this.cerrarModal();
                    this.isSaving = false;
                    this.$apollo.queries.getNomina.refetch();
                }
                else if(response.status == 202){
                    this.erroresRP = response.data.items;
                    this.dialogErrores = true;
                    this.isSaving = false;
                }
                
            })
            .catch(err => {
                this.loadingLottie=false; 
                this.isSaving = false;
                this.$refs.tabla.unselectAll();
                this.selected_general=[];
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        timbrarCanceladosConFecha(){
            if(this.datosTablaTimbres.length == 0){
                this.isSaving = false;
                return
            }
            //this.fraseLoading = "Retimbrando";
            //this.loadingLottie = true;


            let datosEmpleados = [];
            this.datosTablaTimbres.forEach((empleado)=>{
                datosEmpleados.push({
                    empleado_id: empleado.empleado_id,
                    fecha_pago: empleado.fecha_pago
                })
            })

            let parametros = {
                nomina_id : this.nomina.id,
                datos : datosEmpleados
            }


            apiCalculoNomina.timbreMasivo(parametros).then(response => {
                this.cerrarModal();
                this.selected_general = [];
                this.$apollo.queries.getNomina.refetch();
                this.isSaving = false;
            })
            .catch(err => {
                console.error(err);
                this.isLoading    = false;
                this.loadingLottie=false; 
                this.isSaving = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        

        async retimbrar(data, accion){
            let mensaje = "";
            let param = {};

            let timbres = null;
            let timbresValidos = [];

            if(accion == 'empleado'){
                mensaje = "¿Estás seguro que deseas retimbrar al empleado "+data.nombre_completo+"?";
                param = { nominas: [data.id]}
            }
            else if(accion == 'empleados'){
                
                mensaje = "¿Estás seguro que deseas retimbrar los empleados seleccionados?"

                timbres = await this.itemsEmpleados.filter(item => this.selected_general.includes(item.id))
                timbres.forEach(item =>{
                    if(!item.cancelado && !item.retimbrado && item.uuid !== ''){
                        timbresValidos.push(item.id);
                    }
                })

                param = { nominas: timbresValidos}

                if(timbresValidos.length == 0){
                    return;
                }
            }
            Notify.Alert(
                "ADVERTENCIA",
                mensaje,
                "Retimbrar",
                () => {
                    this.fraseLoading = "Retimbrando";
                    this.loadingLottie = true;
                    apiCalculoNomina.retimbrar(param).then(response => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        if(response.status === 202){
                            // mensaje para  response.data.message
                            if(typeof response.data.message === 'string'){
                                Notify.ErrorToast(response.data.message +" Favor de verificar el detalle");
                            }else{
                                let error = Object.values(response.data.message);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                leyenda += " Favor de verificar el detalle";
                                Notify.ErrorToast(leyenda);
                            }

                        }
                        if(response.status === 200){
                            Notify.Success("Timbrado", "El timbrado se realizo correctamente.");
                        }
                        this.getEmpleados(this.nomina.id, null, null, 'updated');
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        correo(data, accion){
            let mensaje = '';
            let param = { nominas: []}

            if(accion === 'empleado'){
                mensaje = "¿Estás seguro que deseas enviar el correo al empleado "+data.nombre_completo+"?";
                param = { nominas: [data.id]}
            }
            else{
                mensaje = "¿Estás seguro que deseas enviar los correos a los empleados seleccionados?. No se enviaran correos a los empleados con el estatus 'Error'"
                param = { nominas: this.selected_general}
            }

            Notify.Alert(
                "ADVERTENCIA",
                mensaje,
                "Enviar",
                () => {
                    this.fraseLoading = "Enviando";
                    this.loadingLottie = true;
                    apiCalculoNomina.enviarCorreo(param).then(response => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        
                        if(response.status === 202){
                            // mensaje para  response.data.message
                            if(typeof response.data.message === 'string'){
                                Notify.ErrorToast(response.data.message +" Favor de verificar el detalle");
                            }else{
                                let error = Object.values(response.data.message);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                leyenda += " Favor de verificar el detalle";
                                Notify.ErrorToast(leyenda);
                            }

                        }
                        if(response.status === 200){
                            Notify.Success("Envio de correo", "El envio de correo se realizo correctamente.");
                        }
                        this.getEmpleados(this.nomina.id, null, null, 'updated');
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.$refs.tabla.unselectAll();
                        this.selected_general=[];
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        async descargar(item = null, tipo){
            this.overlay = true;
            this.fraseOverlay = "Descargando";
            let self = this;
            const FileDownload = require('js-file-download');
            const axios = require('axios');
            let url = 'nominas-timbres/descargar';
            let fecha = item.fecha_final.split('-');
            var nombre_archivo = ''

            if(item.tipo_periodo_especial == 'AGUINALDO'){
                nombre_archivo = item.nombre_completo+'_'+item.claveTipoNomina+'_'+item.numero+'_'+fecha[2]+'-'+fecha[1]+'-'+fecha[0]+'-.'+tipo; 
            }
            else if(item.tipo_periodo_especial == 'FINIQUITO'){
                if(item.tipo_indemnizacion){
                    nombre_archivo = item.nombre_completo+'_'+item.claveTipoNomina+'_'+item.numero+'_'+fecha[2]+'-'+fecha[1]+'-'+fecha[0]+'_INDEMNIZACION.'+tipo; 
                }
                else{
                    nombre_archivo = item.nombre_completo+'_'+item.claveTipoNomina+'_'+item.numero+'_'+fecha[2]+'-'+fecha[1]+'-'+fecha[0]+'-.'+tipo; 
                }
            }
            else{
                nombre_archivo = item.nombre_completo+'_'+item.claveTipoNomina+'_'+fecha[2]+'-'+fecha[1]+'-'+fecha[0]+'-.'+tipo;
            }
            let parametros = {nomina_id:[item.id],tipo}
            try {
                axios({
                method: 'post',
                url: url,
                responseType: 'blob',
                data: parametros,
                })
                .then(function (response) {
                    self.overlay = false;
                    FileDownload(response.data,nombre_archivo);
                })
                .catch(async (error) =>{
                    self.overlay = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    };
                });
            } catch (error) {
                self.overlay = false
                console.log(error);
            }
        },
        descargarTimbres(){
            this.overlay = true;
            this.fraseOverlay = "Descargando";
            let self = this;
            const FileDownload = require('js-file-download');
            const axios = require('axios');
            let url = 'nominas-timbres/descargar';
            var nombre_archivo = "Timbres.zip"; 
            let parametros = {nomina_id:self.selected_general,tipo:"zip"}
            try {
                axios({
                method: 'post',
                url: url,
                responseType: 'blob',
                data: parametros,
                })
                .then(function (response) {
                    self.overlay = false;
                    FileDownload(response.data,nombre_archivo);
                
                })
                .catch((error) => {
                    self.overlay = false
                    self.$refs.tabla.unselectAll();
                    self.selected_general=[];
                    Notify.ErrorToast("No se encontro los registros solicitados.");
                });
            } catch (error) {
                self.overlay = false
                self.$refs.tabla.unselectAll();
                self.selected_general=[];
                console.log(error);
            }
        },
        textColor(errores, uuid){
            if(errores === null && uuid !== ''){
                return '#86EF83' //correcto
            }
            else if(errores != null && uuid === ''){
                return '#D93025' //Error
            }
            else if(errores != null && uuid !== ''){
                return '#D93025' //Error
            }
            else if (uuid === '' && errores === null){
                return '#D93025' //Sin timbrar
            }
        },
        cerrarModal(){
            this.dialog             = false;
            this.dialogCancelar     =  false;
            this.estatusTimbre      = null;
            this.datos              = null;
            this.accionEmp          = null;
            this.empleadoBuscar     = null;
            this.claveMotivo        = '01';
            this.isSaving           = false;
            this.dialogAcumulados   = false;
            this.dialogTimbres      = false;

            if(this.cancelarModal){
                this.selected_general_acumulado = [];
                this.selected_general = [];
            }
        },
        async abrirModalBuscar(){
            setTimeout(() => { document.getElementById("btnModal").click(); }, 100);

            this.itemsSearchEmployed = [];
            this.modelSearchEmployed = [];

            let param = { 
                nomina_id : this.nomina.id,
            };
            
            await apiCalculoNomina.getEmpleadosAcumuladoTimbre(param).then(async response => {
                
                if(this.tab == 0){
                    this.itemsSearchEmployed = await response.acumulados.map(item => {
                        item.nombre_completo = item.empleado.nombre_completo

                        return item
                    })
                }
                else if(this.tab == 1){
                    let empleados = response.timbrados;
                    let itemsTimbres = [];

                    empleados.forEach(empleado => {
                        itemsTimbres.push(empleado[0])
                    });

                    this.itemsSearchEmployed  = itemsTimbres;
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los empleados");
            });


        },
        async buscar(){         
            this.getEmpleados(this.nomina.id,this.empleadoBuscar,this.estatusTimbre, 'updated', this.modelSearchEmployed)
            /* if(this.estatusTimbre == null){
                this.itemsEmpleados = this.itemsEmpleadosOriginal;
            }
            else if(this.estatusTimbre == "cancelado"){
                this.itemsEmpleados = this.itemsEmpleadosOriginal.filter(empleado => empleado.cancelado == true);
            }
            else{
                if(this.estatusTimbre == "correcto"){
                    this.itemsEmpleados = this.itemsEmpleadosOriginal.filter(empleado => (empleado.errores === null &&  empleado.uuid !== '' && empleado.cancelado == false));
                }
                if(this.estatusTimbre == "error"){
                    this.itemsEmpleados = this.itemsEmpleadosOriginal.filter(empleado => (empleado.errores != null &&  empleado.uuid === '' && empleado.cancelado == false));
                }
                if(this.estatusTimbre == "sinTimbrar"){
                    this.itemsEmpleados = this.itemsEmpleadosOriginal.filter(empleado => (empleado.errores === null &&  empleado.uuid === '' && empleado.cancelado == false));
                }
            } */
            await this.cerrarModal()
        },
        desacumular(data){
            let param = {};
            param = { 
                nomina_id:this.nomina.id, 
                empleados:[data.empleado_id]
            };
            let self = this;
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas realizar el proceso de desacumulación?",
                "Desacumular",
                () => {
                    apiCalculoNomina.desacumular(param).then(response => {
                        Notify.Success("Operación exitosa", "La desacumulación se ha realizado satisfactoriamente.");
                        self.getEmpleados(self.nomina.id, null, null, 'updated');
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },

        /*************************************************************************************************/
        /*************************************************************************************************/
        /*************************************************************************************************/
        /* Metodos para la pestaña empleados acumulados */
        /*************************************************************************************************/
        /*************************************************************************************************/
        /*************************************************************************************************/

        async abrirModalTimbre(datosEmpleado = null){
            this.dialogAcumulados =  true;
            this.isLoadingModalAcumulados = true;
            this.tituloModal = 'Timbrar';
            this.imagen = "/static/modal/brigadasUpdate.svg";

            this.datosTablaAcumulados = [];

            if(datosEmpleado){
                this.selected_general_acumulado = [];
                this.selected_general_acumulado.push(datosEmpleado.empleado_id);
                this.cancelarModal = true;
            }
            else{
                this.cancelarModal = false;
            }

            this.datosTablaAcumulados = await this.itemsEmpleadosTablaAcumulados.filter((empleado) => this.selected_general_acumulado.includes(empleado.empleado_id))

            this.isLoadingModalAcumulados = false;
           
        },
        async timbrarModal(){
            let datosEmpleados = [];
            this.datosTablaAcumulados.forEach((empleado)=>{
                datosEmpleados.push({
                    empleado_id: empleado.empleado_id,
                    fecha_pago: empleado.periodo.fecha_de_pago
                })
            })

            let parametros = {
                nomina_id : this.nomina.id,
                datos : datosEmpleados
            }

            //this.fraseLoading = "Timbrando";
            //this.loadingLottie = true;

            await apiCalculoNomina.timbreMasivo(parametros).then(response => {
                if(response.status == 200){
                    this.cerrarModal();
                    this.$apollo.queries.getNomina.refetch();
                }
                else if(response.status == 202){
                    this.erroresRP = response.data.items;
                    this.dialogErrores = true;
                }

            })
            .catch(err => {
                console.error(err);
                this.isLoading    = false;
                this.loadingLottie=false; 
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },

        /**
         * @method formatoMoneda Este método sirve para formatear el valor a formato moneda en la tabla principal.
         * @description Este método sirve para formatear el valor a formato moneda en la tabla principal.
         */
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },

        /**
         * @method selectAllAcumulados Este método se realiza al dar clic en el check que esta en el header de la tabla principal.
         * @description Este método se realiza al dar clic en el check que esta en el header de la tabla principal. Se selecciones todos los check o se deseleccionan.
         * Se ejecuta con una emit del componente tabla.
         * @param {boolean} isSelected variable para saber si check se esta seleccionado o no.
         * @param {object} items variable donde vienen todos los items de los empledos.
         */

        selectAllAcumulados(isSelected, items) {
            this.selected_general_acumulado = items;
        },
        /**
         * @method desacumularAcumulado Este método sirve para desacumular
         * @description Este método sirve para desacumular un empleado.
         * @param {object} data variable donde vienen todos los datos del empleado.
         */
        async desacumularAcumulado(data, accion = null){
            let param = {};
            if(accion == 'empleado'){
                param = { 
                    nomina_id:this.nomina.id, 
                    empleados:[data.empleado_id]
                };
            }
            else if(accion == 'empleados'){

                if(this.tab == 0){
                    if(this.selected_general_acumulado.length == 0){
                        Notify.ErrorToast("Seleccionar al menos un registro");
                        return;
                    }

                    param = { 
                        nomina_id:this.nomina.id, 
                        empleados:this.selected_general_acumulado
                    };
                }
                else if(this.tab == 1) {
                    if(this.selected_general.length == 0){
                        Notify.ErrorToast("Seleccionar al menos un registro");
                        return;
                    }
                    
                    
                    /* let itemsRegistrosTimbres = await this.itemsEmpleados.filter((empleado) => 
                        this.selected_general.includes(empleado.id) && 
                        (
                            (empleado.cancelado == true && empleado.retimbrado == false) || 
                            (empleado.cancelado==false && empleado.retimbrado==false && empleado.errores != null) 
                        )
                    ) */


                    /* ***********************************************************************
                    **************************************************************************
                    **************************CAMBIAR*****************************************
                    **************************************************************************
                    ************************************************************************ */

                    let itemsRegistrosTimbres = await this.itemsEmpleados.filter((empleado) => 
                        this.selected_general.includes(empleado.id) 
                    )


                    let idEmpleados = await itemsRegistrosTimbres.map(registro => registro.empleado_id)


                    if(idEmpleados.length == 0 ){
                        Notify.ErrorToast("No hay empleados para desacumular");
                        return;
                    }

                    param = { 
                        nomina_id:this.nomina.id, 
                        empleados:idEmpleados
                    };
                    
                }
                
            }
            else{
                Notify.ErrorToast("no se eligio una accion");
                return;
            }
            let self = this;
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas realizar el proceso de desacumulación?",
                "Desacumular",
                () => {
                    apiCalculoNomina.desacumular(param).then(response => {
                        Notify.Success("Operación exitosa", "La desacumulación se ha realizado satisfactoriamente.");
                        self.getEmpleados(self.nomina.id, null, null, 'updated');
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )

        },

        async getEmpleados(nominas_id, empleado=null, estatus=null, accion='updated', empleados = []) {
            this.isLoading = true;
            let param = { 
                nomina_id : nominas_id,
                empleado: empleado,
                estatus: estatus,
                empleados: empleados.length == 0 ? null: JSON.stringify(empleados),
            };
            
            await apiCalculoNomina.getEmpleadosAcumuladoTimbre(param).then(response => {
                this.tipoDePeriodo = response.tipo;
                if(response.tipo == 'FINIQUITO'){
                    this.columns         = [
                        {
                            label:"UUID",
                            name:"uuid",
                            filterable:false
                        },
                        {
                            label:"Tipo",
                            name:"tipo",
                            filterable:false
                        },
                        {
                            label: "Empleado", 
                            name: "nombre_completo",
                            filterable: false
                        },
                        {
                            label: "Estatus", 
                            name: "estatus",
                            filterable: false
                        },
                        {
                            label: 'Opciones',
                            name: 'detalle',
                            filterable: false,
                            align: 'center',
                        }
                    ]
                }
                else{
                    this.columns         = [
                        {
                            label:"UUID",
                            name:"uuid",
                            filterable:false
                        },
                        {
                            label: "Empleado", 
                            name: "nombre_completo",
                            filterable: false
                        },
                        {
                            label: "Estatus", 
                            name: "estatus",
                            filterable: false
                        },
                        {
                            label: 'Opciones',
                            name: 'detalle',
                            filterable: false,
                            align: 'center',
                        }
                    ]
                }

                this.itemsEmpleadosTablaAcumulados= response.acumulados;

                let empleados = response.timbrados;
                let itemsTimbres = [];

                empleados.forEach(empleado => {
                    empleado.forEach(timbre => {
                        itemsTimbres.push(timbre)
                    });
                });

                this.itemsEmpleados  = itemsTimbres;

                if(accion=='created' && response.acumulados.length==0){
                    this.tab=1;
                }
                this.isLoading    = false;
            })
            .catch(err => {
                console.error(err);
                this.isLoading    = false;
                Notify.ErrorToast("Ocurrió un error al cargar los empleados");
            });
        },

        async getEmpleadosSearch(nominas_id) {
            let param = { 
                nomina_id : nominas_id,
            };
            
            await apiCalculoNomina.getEmpleadosAcumuladoTimbre(param).then(response => {
                
                this.itemsEmpleadosTablaAcumulados= response.acumulados;

                let empleados = response.timbrados;
                let itemsTimbres = [];

                empleados.forEach(empleado => {
                    empleado.forEach(timbre => {
                        itemsTimbres.push(timbre)
                    });
                });

                this.itemsEmpleados  = itemsTimbres;

            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los empleados");
            });
        },

        socketProcesandoNomina(){
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'ASDASD2121',
                wsHost: env.serverURL.url,
                wsPort: env.pusherPort,
                wssPort: env.pusherPort,
                forceTLS: env.serverURL.tls,
                disableStats: true,
                enabledTransports: ['ws', 'wss']
            });

            window.Echo.channel(`progreso-nomina-${this.nomina.id}`).listen('NominaTimbreProgress', (e) => {
                this.nominaPorcentaje   = e.message.porcentaje;
                this.nominaTotal        = e.message.total;      
                this.nominaProcesados   = e.message.procesados;
                if(e.message.porcentaje == 100){
                    this.procesados = e.message.procesados;
                    this.conError   = e.message.errores;
                    this.dialogMensaje = true;
                    setTimeout(()=>{ this.clickOverlayProceso() }, 5000);
                }

            });
        },
        cerrarModalMensaje(){
            this.dialogMensaje = false;
            this.procesados = 0;
            this.conError   = 0;
            this.tab=1;
        },
        clickOverlayProceso(){
            this.$apollo.queries.getNomina.refetch();
            this.getEmpleados(this.nomina.id, null, null, 'updated');
        },
        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresRP = [];
        }
    },
    beforeCreate(){
        if(!this.$session.get('detalleTimbre')){
            this.$router.push('/dashboard');
        }
    },
    created() {
        this.nomina = this.$session.get('detalleTimbre');
        this.filters = {AND:[{ column: 'NOMINA_ID', value: this.nomina.id }]};
        this.filtersAcumulado.nomina_id = this.nomina.id;
        this.getEmpleados(this.nomina.id, null, null, 'created');
    },
    mounted(){
        this.$apollo.queries.getNomina.skip = false;
    },
    updated() {        
        if(document.querySelector('#idTablaAcumulados')){
            document.querySelector('#idTablaAcumulados thead tr th:nth-child(1)').style.width   = "115px";
        }

        if(document.querySelector('#tableScrollSimple')){
            document.querySelector('#tableScrollSimple thead tr th:nth-child(1)').style.paddingRight   = "4px";
            document.querySelector('#tableScrollSimple thead tr th:nth-child(1)').style.width   = "80px";

            if(this.tipoDePeriodo == 'FINIQUITO'){
                document.querySelector('#tableScrollSimple thead tr th:nth-child(6)').style.width   = "200px";
                document.querySelector('#tableScrollSimple thead tr th:nth-child(6)').style.paddingRight   = "8px";
                document.querySelector('#tableScrollSimple thead tr th:nth-child(6)').style.paddingLeft   = "8px";
            }
            else{
                document.querySelector('#tableScrollSimple thead tr th:nth-child(5)').style.width   = "200px";
                document.querySelector('#tableScrollSimple thead tr th:nth-child(5)').style.paddingRight   = "8px";
                document.querySelector('#tableScrollSimple thead tr th:nth-child(5)').style.paddingLeft   = "8px";
            }
            
        }
    },
    apollo: {
        getNomina: {
            query       : queriesNomina.getNominaQuery,
            fetchPolicy : 'network-only',
            manual: true,
            variables() {
                return {
                    id : parseInt(this.nomina.id)
                }
            },
            skip() {
                return true
            },
            result({data, loading, error}) {
                if(error != undefined){
                    console.log("Error graphQL nómina");
                }
                else{
                    this.getNomina = data.nomina;
                    if(data.nomina.procesando_timbres){
                        this.overlayProceso = true;
                        this.socketProcesandoNomina();
                    }
                    else{
                        this.overlayProceso = false;
                    }
                    
                }
            }
        }
    } 
    
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .btnBuscar{
        width : 42px!important;
        height: 42px!important;
    }
    .btnBuscar:hover{
        height: 42px!important;
        color:#96999A;
    }
    .btn-acciones{
        width : 42px!important;
        height: 42px!important;
    }
    .btn-acciones:hover{
        color: #FFFFFF;    
    }
    .titleModalBuscar {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
        padding-bottom: 25px;
    }
    .btnSpeelDial .v-speed-dial__list{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
    }
    .tabText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #1E2245;
        text-transform: none;
    }
    .tabTextDisable{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        color: #BDBDBD;
        text-transform: none;
    }
    .pNota{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #D93025;
    }
    .radioLabel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }

    th{
        color:#828282;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 8px;
        text-align: left;
    }

    .tbl-modal tbody td{
        color: #1E2144;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 2px;
        text-align: left;
    }

    .tbl-modal {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-modal .tbl-header-modal {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
    }
    .lastth {
        border-top-right-radius: 20px !important;
    }
    .emptyTableModal{
        color: #c5c5c5 !important;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
    }
    .cssTitleProgress{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .btnProgress {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .textTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '500';
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .titleModalTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '600';
        font-size: 18px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .disable-events {
        pointer-events: none
    }
    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    .alert-retimbrado{
        border-left: 9px solid #D93025;
        padding-left: 10px;

        background: rgba(250, 230, 229, 0.6);
        border-radius: 5px
    }

    .alert-titulo {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #D93025;

    }

    .alert-sub {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        color: #C5352B;
    }

    .alert-sub-2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        color: #C5352B;
    }

    .icono-espacio{
        width: 100px;
        height: 30px;
    }
</style>
